@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css?family=Philosopher:400,400i,700,700i|Poppins:200,200i,300,300i,400,400i,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Philosopher:400,400i,700,700i|Poppins:200,200i,300,300i,400,400i,500,600,700,800,900&display=swap");
ion-button ion-badge {
  position: absolute;
  top: 0rem;
  right: 0rem;
  opacity: 0.9;
}

ion-button ion-badge ~ i {
  margin-right: 0rem;
}

.modal-fullscreen .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

ion-card {
  cursor: pointer;
}

ion-footer {
  color: aqua;
}

/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_extend.scss */
.flex-center-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
/* Large Mobile :480px. */
/* 1. Theme default css */
/* Normal desktop :1200px. */
/* Normal desktop :992px. */
/* Tablet desktop :768px. */
/* small mobile :320px. */
/* Large Mobile :480px. */
/* line 6, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
body {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.img {
  max-width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

a,
.button {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

a:focus,
.button:focus, button:focus {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

a:focus,
a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  text-decoration: none;
}

a,
button {
  color: #1F1F1F;
  outline: medium none;
}

h1, h2, h3, h4, h5 {
  font-family: "Philosopher", sans-serif;
  color: #001D38;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #596672;
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
}

label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

*::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}

*::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

*::placeholder {
  color: #cccccc;
  font-size: 14px;
  opacity: 1;
}

h3 {
  font-size: 24px;
}

/* line 115, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-65 {
  margin-bottom: 67px;
}

/* line 119, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.black-bg {
  background: #020c26 !important;
}

/* line 123, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.white-bg {
  background: #ffffff;
}

/* line 126, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.gray-bg {
  background: #f5f5f5;
}

/* line 131, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.overlay {
  position: relative;
  z-index: 0;
}

/* line 135, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.overlay::before {
  position: absolute;
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001d38+0,1a0d01+100&0.7+0,0.6+100 */
  background: -moz-linear-gradient(left, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#b3001d38", endColorstr="#991a0d01",GradientType=1 );
  /* IE6-9 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* line 151, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.overlay2 {
  position: relative;
  z-index: 0;
}

/* line 155, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.overlay2::before {
  position: absolute;
  content: "";
  background-color: #001D38;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
}

/* line 167, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.overlay_03 {
  position: relative;
  z-index: 0;
}

/* line 171, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.overlay_03::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #001D38;
  opacity: 0.6;
  content: "";
  z-index: -1;
}

/* line 184, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.bradcam_overlay {
  position: relative;
  z-index: 0;
}

/* line 188, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.bradcam_overlay::before {
  position: absolute;
  content: "";
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#5db2ff+0,7db9e8+100&1+24,0+96 */
  background: -moz-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #5db2ff 0%, #65b4f9 24%, rgba(124, 185, 233, 0) 96%, rgba(125, 185, 232, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#5db2ff", endColorstr="#007db9e8",GradientType=1 );
  /* IE6-9 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 1;
}

/* line 204, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

/* line 208, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.pt-120 {
  padding-top: 120px;
}

/* button style */
/* line 214, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div {
  background: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 50px;
  color: #707070;
  background-color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 50px;
  font-size: 15px;
  line-height: 50px;
  border: 1px solid #4D6174;
  left: 150px;
  color: #fff;
}

/* line 242, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 150px;
}

/* line 247, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-next i {
  position: relative;
  right: 0;
}

/* line 254, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  top: 0px;
}

/* line 264, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.owl-carousel:hover .owl-nav div {
  opacity: 1;
  visibility: visible;
}

/* line 267, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.owl-carousel:hover .owl-nav div:hover {
  color: #fff;
  background: #001D38;
  border: 1px solid transparent;
}

/* line 277, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-20px {
  margin-bottom: 20px;
}

/* line 281, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-55 {
  margin-bottom: 55px;
}

/* line 284, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-75 {
  margin-bottom: 75px;
}

@media (max-width: 767px) {
  /* line 284, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
  .mb-75 {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 284, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
  .mb-75 {
    margin-bottom: 30px;
  }
}
/* line 293, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-40 {
  margin-bottom: 40px;
}

/* line 296, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-20 {
  margin-bottom: 20px;
}

/* line 301, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/theme-default.scss */
.mb-60 {
  margin-bottom: 50px !important;
}

/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn {
  background: #fff;
  color: #6c9720;
  display: inline-block;
  padding: 18px 44px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #6c9720;
  letter-spacing: 3px;
  text-align: center;
  color: #6c9720 !important;
  text-transform: uppercase;
  cursor: pointer;
}

/* line 17, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn:hover {
  background: #6c9720;
  color: #fff !important;
  border: 1px solid #6c9720;
}

/* line 22, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn:focus {
  outline: none;
}

/* line 25, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn.large-width {
  width: 220px;
}

/* line 29, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3 {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001D38+0,fd8e5e+100 */
  background: #6c9720;
  /* IE6-9 */
  color: #fff;
  display: inline-block;
  padding: 19px 50px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  letter-spacing: 2px;
}

/* line 49, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001D38+0,fd8e5e+100 */
  background: #001D38;
  /* IE6-9 */
  color: #fff !important;
}

/* line 55, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3:focus {
  outline: none;
}

/* line 58, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3.large-width {
  width: 220px;
}

/* line 63, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-white {
  color: #fff;
  display: inline-block;
  padding: 13px 27px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  color: #fff !important;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
  letter-spacing: 2px;
}

/* line 80, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-white:hover {
  background: #28AE61;
  color: #fff !important;
  border: 1px solid transparent;
}

/* line 85, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-white i {
  margin-right: 2px;
}

/* line 88, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-white:focus {
  outline: none;
}

/* line 91, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-white.large-width {
  width: 220px;
}

/* line 96, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-line {
  color: #fff !important;
  display: inline-block;
  padding: 14px 47px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  border: 1px solid #001D38;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  text-align: center;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #001D38;
  cursor: pointer;
  letter-spacing: 2px;
}

/* line 113, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-line:hover {
  color: #001D38 !important;
  border: 1px solid #001D38;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001D38+0,fd8e5e+100 */
  background: #fff;
}

/* line 119, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-line:focus {
  outline: none;
}

/* line 122, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn3-line.large-width {
  width: 220px;
}

/* line 126, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn2 {
  background: transparent;
  color: #fff;
  display: inline-block;
  padding: 18px 24px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: 0;
  border: 1px solid #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
}

/* line 138, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn2:hover {
  background: #fff;
  color: #131313 !important;
}

/* line 142, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.boxed-btn2:focus {
  outline: none;
}

/* line 146, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.line-button {
  color: #919191;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  padding-right: 5px;
  padding-bottom: 2px;
}

/* line 154, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.line-button::before {
  position: absolute;
  content: "";
  background: #919191;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
}

/* line 163, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.line-button:hover {
  color: #009DFF;
}

/* line 166, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.line-button:hover::before {
  background: #009DFF;
}

/* line 170, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.book_now {
  display: inline-block;
  font-size: 14px;
  color: #009DFF;
  border: 1px solid #009DFF;
  text-transform: capitalize;
  padding: 10px 25px;
}

/* line 177, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_btn.scss */
.book_now:hover {
  background: #009DFF;
  color: #fff;
}

/* line 2, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
.section_title h3 {
  font-size: 50px;
  font-weight: 700;
  color: #001D38;
  position: relative;
  z-index: 0;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  /* line 2, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 30px;
    line-height: 36px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 36px;
    line-height: 42px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  /* line 2, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title h3 {
    font-size: 35px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  /* line 22, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 22, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title h3 br {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  /* line 22, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title h3 br {
    display: none;
  }
}
/* line 34, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
.section_title p {
  font-size: 18px;
  color: #777;
  line-height: 28px;
  margin-bottom: 0;
  font-weight: 400;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  /* line 41, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .section_title p br {
    display: none;
  }
}
/* line 49, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
.section_title h4 {
  font-size: 22px;
  font-weight: 500;
  color: #001D38;
  padding-bottom: 21px;
}

/* line 55, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
.mb-50 {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 55, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .mb-50 {
    margin-bottom: 40px;
  }
}
/* line 62, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
.mb-70 {
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  /* line 62, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .mb-70 {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 62, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_section_title.scss */
  .mb-70 {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  /* line 4, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
  .mobile_menu {
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 9;
  }
}
/* line 13, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav {
  background: #fff;
  float: right;
  margin-top: 0;
  padding: 0;
  width: 95%;
  padding: 0;
  border-radius: 0px;
  margin-top: 5px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 11px;
}

/* line 28, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav a:hover {
  background: transparent;
  color: #FDAE5C;
}

/* line 32, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav a.active {
  color: #FDAE5C;
}

@media (max-width: 767px) {
  /* line 35, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 35, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
  .slicknav_menu .slicknav_nav a i {
    display: none;
  }
}
/* line 44, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav .slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: -40px;
  position: relative;
  z-index: 99;
  border: 1px solid #ddd;
  top: 3px;
  right: 5px;
  top: -36px;
}

/* line 55, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav .slicknav_btn .slicknav_icon {
  margin-right: 6px;
  margin-top: 3px;
  position: relative;
  padding-bottom: 3px;
  top: -11px;
  right: -5px;
}

@media (max-width: 767px) {
  /* line 12, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
  .slicknav_menu {
    margin-right: 0px;
  }
}
/* line 72, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_nav .slicknav_arrow {
  float: right;
  font-size: 22px;
  position: relative;
  top: -9px;
}

/* line 78, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 5px;
  margin-top: -5px;
  top: -31px;
}

/* line 92, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_btn {
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  z-index: 99;
  border: none;
  border-radius: 3px;
  top: 5px;
  padding: 5px;
  right: 0;
  margin-top: -5px;
  top: -41px;
}

/* line 106, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slick-nav.scss */
.slicknav_menu .slicknav_nav {
  top: -1px;
}

/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area {
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 9;
  position: absolute;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area {
    padding-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area {
    padding-top: 0;
  }
}
/* line 17, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area {
  background: #fff;
  background: transparent;
  padding: 0 !important;
}

/* line 36, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .logo {
  text-align: left;
}

@media (max-width: 767px) {
  /* line 36, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .logo {
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 36, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .logo {
    text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  /* line 36, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .logo {
    text-align: left;
  }
}
@media (max-width: 767px) {
  /* line 50, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .logo img {
    width: 110px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 50, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .logo img {
    width: 110px;
  }
}
/* line 62, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .say_hello {
  text-align: right;
  padding-right: 48px;
}

/* line 65, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .say_hello a {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  position: relative;
  padding-bottom: 5px;
}

/* line 71, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .say_hello a::before {
  position: absolute;
  background: #FFE8C3;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0;
  content: "";
}

/* line 82, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 86, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .search_button {
    margin-right: 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 86, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .search_button {
    margin-right: 15px;
  }
}
/* line 95, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .search_button a i {
  color: #E8E8E8;
}

/* line 102, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li {
  display: inline-block;
}

/* line 105, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li a {
  color: #A8A7A0;
  margin: 0 10px;
  font-size: 15px;
}

/* line 109, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .socail_links ul li a:hover {
  color: #fff;
}

/* line 116, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .book_btn {
  margin-left: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 116, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .book_btn {
    margin-left: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 116, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .book_btn {
    margin-left: 0;
  }
}
/* line 124, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .book_btn a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdae5c+0,fd8e5e+100 */
  background: #fdae5c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fdae5c 0%, #fd8e5e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#fdae5c", endColorstr="#fd8e5e",GradientType=0 );
  /* IE6-9 */
  padding: 13px 26px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
  color: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 124, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .Appointment .book_btn a {
    padding: 12px 20px;
  }
}
/* line 142, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .Appointment .book_btn a:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fdae5c+0,fd8e5e+100 */
  background: #fdae5c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fdae5c 0%, #fd8e5e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fdae5c 0%, #fd8e5e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#fdae5c", endColorstr="#fd8e5e",GradientType=0 );
  /* IE6-9 */
}

/* line 154, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu {
  text-align: center;
  padding: 12px 0;
}

/* line 158, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li {
  display: inline-block;
  position: relative;
  margin: 0 20px;
}

/* line 168, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a {
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  padding: 0px 0px 0px 0px;
  font-family: "Poppins", sans-serif;
  position: relative;
  text-transform: capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 168, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 168, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a {
    font-size: 15px;
  }
}
/* line 187, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li a i {
  font-size: 9px;
}

@media (max-width: 767px) {
  /* line 187, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 187, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .main-header-area .main-menu ul li a i {
    display: none !important;
  }
}
/* line 228, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu {
  position: absolute;
  left: 0;
  top: 160%;
  background: #fff;
  width: 200px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  opacity: 0;
  visibility: hidden;
  text-align: left;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

/* line 240, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li {
  display: block;
}

/* line 242, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li a {
  padding: 10px 15px;
  position: inherit;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: block;
  color: #000;
}

/* line 248, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li a::before {
  display: none;
}

/* line 252, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li .submenu li:hover a {
  color: #000;
}

/* line 257, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}

/* line 262, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:hover > a::before {
  opacity: 1;
  transform: scaleX(1);
}

/* line 266, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area .main-menu ul li:first-child a {
  padding-left: 0;
}

/* line 272, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area.sticky {
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 990;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.96);
  background: #1A0D01;
}

/* line 300, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .main-menu {
  padding: 0;
}

/* line 303, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .header_bottom_border {
  border-bottom: none;
}

/* line 306, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .main-header-area.sticky .header_bottom_border.white_border {
  border-bottom: none !important;
}

/* line 330, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area {
  padding: 12px 0;
  background: #001D38;
}

@media (max-width: 767px) {
  /* line 333, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .header-top_area .social_media_links {
    text-align: center;
  }
}
/* line 337, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .social_media_links a {
  font-size: 15px;
  color: #C7C7C7;
  margin-right: 12px;
}

/* line 341, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .social_media_links a:hover {
  color: #FDAE5C;
}

/* line 347, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .header_left p {
  color: #727272;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
}

/* line 354, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list {
  text-align: right;
}

@media (max-width: 767px) {
  /* line 354, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .header-top_area .short_contact_list {
    text-align: center;
  }
}
/* line 360, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li {
  display: inline-block;
}

/* line 362, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li a {
  font-size: 13px;
  color: #919191;
  margin-right: 50px;
}

@media (max-width: 767px) {
  /* line 362, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .header-top_area .short_contact_list ul li a {
    margin-left: 0;
    margin: 0 5px;
  }
}
/* line 371, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .short_contact_list ul li a i {
  color: #FDAE5C;
  margin-right: 7px;
}

/* line 379, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .header-top_area .header_right.d-flex {
  display: flex;
  justify-content: flex-end;
}

/* line 384, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
.header-area .search_btn i {
  color: #fff;
  font-size: 15px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 388, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .search_btn {
    position: relative;
    right: 10px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  /* line 388, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_header.scss */
  .header-area .search_btn {
    position: relative;
    right: 10px;
  }
}
/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_bg_1 {
  background-image: url(/assets/img/banner.jpg);
}

/* line 4, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
/* line 7, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area {
  position: relative;
  z-index: 0;
}

/* line 20, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider {
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* line 25, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 {
  height: 450px;
}

@media (max-width: 767px) {
  /* line 25, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider.single_slider2 {
    height: auto;
  }
}
/* line 30, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 .property_wrap {
  position: relative;
  top: 166px;
}

@media (max-width: 767px) {
  /* line 30, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider.single_slider2 .property_wrap {
    top: 0;
  }
}
/* line 37, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 .property_wrap .slider_text h3 {
  font-size: 42px;
  margin-bottom: 51px;
}

/* line 42, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider.single_slider2 .property_wrap .property_form {
  background: #001D38;
}

@media (max-width: 767px) {
  /* line 20, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 20, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider {
    height: 600px;
  }
}
@media (max-width: 767px) {
  /* line 54, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text {
    margin: 100px 0 50px 0;
  }
}
/* line 58, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text h3 {
  font-family: "Philosopher", sans-serif;
  font-size: 60px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: 600;
  line-height: 70px;
  margin-bottom: 27px;
  color: #fff;
}

/* line 67, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text h3 span {
  font-weight: 700;
}

@media (max-width: 767px) {
  /* line 58, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 30px;
    line-height: 45px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 58, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text h3 {
    font-size: 54px;
  }
}
/* line 82, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text p {
  font-size: 20px;
  font-weight: 400;
  color: #FFF;
  margin-bottom: 24px;
  margin-top: 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* line 82, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text p {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  /* line 82, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text p {
    font-size: 16px;
  }
}
/* line 95, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider .slider_text .video_service_btn > a {
  margin-right: 15px;
}

@media (max-width: 767px) {
  /* line 95, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .slider_text .video_service_btn > a {
    margin-bottom: 20px;
  }
}
/* line 102, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single_slider .property_form {
  padding: 30px;
  background: rgba(0, 28, 56, 0.6);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  /* line 102, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .property_form {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  /* line 109, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .property_form .form_wrap {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 109, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single_slider .property_form .form_wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
/* line 120, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .serach_icon a {
  width: 45px;
  height: 45px;
  background: #FD8E5E;
  text-align: center;
  line-height: 45px;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
}

@media (max-width: 767px) {
  /* line 120, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .serach_icon a {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 120, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .serach_icon a {
    width: 50%;
  }
}
/* line 137, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .serach_icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: end;
  -moz-align-items: end;
  -ms-align-items: end;
  align-items: end;
}

@media (max-width: 767px) {
  /* line 137, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .serach_icon {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 137, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .serach_icon {
    width: 50%;
    align-items: center;
    margin-top: 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 149, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single-field {
    margin-bottom: 20px;
  }
}
/* line 153, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.range_slider {
  width: 100%;
}

/* line 155, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.range_slider #slider {
  margin: 0;
}

/* line 161, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field .nice-select .list {
  color: #000;
  border-radius: 0;
}

/* line 165, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.max_width {
  margin-right: 24px;
}

@media (max-width: 767px) {
  /* line 165, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single-field.max_width {
    margin-right: 0;
  }
}
/* line 170, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.max_width label {
  display: block;
  font-size: 15px;
  color: #FDAE5C;
}

/* line 176, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.max_width .nice-select.wide {
  width: 166px;
  background: transparent;
  color: #C7C7C7;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid rgba(255, 255, 255, 0.4);
  height: 45px;
  line-height: 45px;
}

@media (max-width: 767px) {
  /* line 176, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single-field.max_width .nice-select.wide {
    width: 100%;
    margin-bottom: 15px;
  }
}
/* line 190, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.max_width .nice-select::after {
  content: "";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 8px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: "themify";
  color: #ddd;
  color: #FFFFFF;
  top: 5px;
  font-size: 12px;
}

/* line 208, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.min_width {
  margin-right: 24px;
}

@media (max-width: 767px) {
  /* line 208, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single-field.min_width {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
/* line 214, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.min_width label {
  display: block;
  font-size: 15px;
  color: #FDAE5C;
}

/* line 220, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.min_width .nice-select.wide {
  width: 110px;
  background: transparent;
  color: #C7C7C7;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid rgba(255, 255, 255, 0.4);
  height: 45px;
  line-height: 45px;
}

@media (max-width: 767px) {
  /* line 220, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
  .slider_area .single-field.min_width .nice-select.wide {
    width: 100%;
    margin-bottom: 15px;
  }
}
/* line 234, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .single-field.min_width .nice-select::after {
  content: "";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 30px;
  top: 8px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: "themify";
  color: #ddd;
  color: #FFFFFF;
  top: 5px;
  font-size: 12px;
}

.owl-carousel {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

/* line 253, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .owl-carousel .owl-nav div {
  height: 120px;
  left: 0px;
  width: 50px;
  color: #fff;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  left: 50px;
  font-size: 14px;
  border: none;
  left: 150px;
  line-height: 120px;
  background: rgba(255, 255, 255, 0.2);
  left: 0;
  border: none !important;
}

/* line 268, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .owl-carousel .owl-nav div.owl-next {
  right: 0;
  left: auto;
}

/* line 272, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
.slider_area .owl-carousel .owl-nav div:hover {
  background: white;
  color: #001D38;
  border: none;
}

/* line 279, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_slider.scss */
/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area {
  padding-top: 160px;
  padding-bottom: 200px;
  background: #FFFBF5;
  position: relative;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area {
    padding-top: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 10, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
}
/* line 14, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_info_wrap h3 {
  font-size: 50px;
  font-family: "Philosopher", sans-serif;
  color: #001D38;
  line-height: 60px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 14, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area .about_info_wrap h3 {
    font-size: 30px;
    line-height: 38px;
  }
}
@media (max-width: 767px) {
  /* line 24, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area .about_info_wrap h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 24, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area .about_info_wrap h3 br {
    display: none;
  }
}
/* line 33, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_info_wrap .long_dash {
  height: 3px;
  width: 70px;
  background: #6c9720;
  display: inline-block;
  margin-bottom: 34px;
  margin-top: 17px;
}

/* line 41, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_info_wrap p {
  font-size: 16px;
  line-height: 28px;
  color: #596672;
  margin-bottom: 50px;
}

/* line 47, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_info_wrap .food_list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 767px) {
  /* line 47, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area .about_info_wrap .food_list {
    display: block;
  }
}
/* line 52, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_info_wrap .food_list li {
  margin-right: 50px;
}

@media (max-width: 767px) {
  /* line 52, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area .about_info_wrap .food_list li {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
/* line 58, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_info_wrap .food_list li span {
  padding-left: 14px;
  color: #6c9720;
  font-size: 16px;
  font-weight: 400;
}

/* line 67, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_img {
  position: relative;
}

/* line 69, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_img .img_1 {
  padding-left: 80px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

/* line 72, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_img .img_1 img {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 100%;
}

/* line 77, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_img .small_img {
  position: absolute;
  margin-top: -170px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: 10px solid #fff;
}

@media (max-width: 767px) {
  /* line 77, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .about_area .about_img .small_img {
    position: absolute;
    margin-top: -80px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 10px solid #fff;
    width: 210px;
  }
}
/* line 91, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .about_img .small_img img {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 100%;
}

/* line 97, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .icon_1 {
  position: absolute;
  top: 7%;
  left: 4%;
}

/* line 102, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .icon_3 {
  position: absolute;
  right: 4%;
  bottom: 10%;
}

/* line 107, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.about_area .icon_2 {
  position: absolute;
  bottom: -12%;
  left: 6%;
}

/* line 115, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.main_about_wrap {
  padding-top: 145px;
  padding-bottom: 130px;
}

@media (max-width: 767px) {
  /* line 115, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .main_about_wrap {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 115, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .main_about_wrap {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
/* line 128, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.main_about_wrap h3 {
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 128, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .main_about_wrap h3 {
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 128, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
  .main_about_wrap h3 {
    font-size: 35px;
  }
}
/* line 139, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.main_about_wrap p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 46px;
  margin-top: 24px;
}

/* line 146, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.main_about_wrap .about_thumb {
  margin-bottom: 30px;
}

/* line 148, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_about.scss */
.main_about_wrap .about_thumb img {
  width: 100%;
}

/* line 2, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 160px 0;
}

/* line 9, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* line 13, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots div.owl-dot {
  width: 7px;
  height: 7px;
  background: #596672;
  display: inline-block;
  margin: 0 7.5px;
  border-radius: 50%;
}

/* line 20, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots div.owl-dot.active {
  position: relative;
  background: #596672;
}

/* line 23, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-dots div.owl-dot.active:before {
  position: absolute;
  border: 2px solid #001D38;
  content: "";
  left: -4px;
  top: -4px;
  bottom: -4px;
  right: -4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@media (max-width: 767px) {
  /* line 2, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
  .testimonial_area {
    padding: 50px 0;
  }
}
/* line 39, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area.overlay::before {
  opacity: 0.4;
  background: #fff;
}

/* line 43, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial {
  margin-bottom: 100px;
  margin-top: 20px;
}

/* line 46, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .author_opinion {
  background: #fff;
  padding: 50px 40px;
  border-radius: 10px;
  border: 2px solid #6c9720;
  margin-bottom: 30px;
  position: relative;
}

/* line 53, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .author_opinion:before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 20px;
  width: 20px;
  height: 20px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  bottom: -11px;
  border-bottom: 2px solid #6c9720;
  border-right: 2px solid #6c9720;
}

/* line 66, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .author_opinion:after {
  position: absolute;
  content: "";
  top: -15px;
  width: 39px;
  height: 29px;
  z-index: 999;
  background-size: 100% 100%;
}

/* line 77, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial p {
  color: #596672;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0;
}

@media (min-width: 320px) and (max-width: 1500px) {
  /* line 83, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
  .testimonial_area .single_testmonial p br {
    display: none;
  }
}
/* line 91, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author .name {
  overflow: hidden;
  padding-left: 15px;
  position: relative;
  top: 13px;
}

/* line 97, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author .thumb {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  float: left;
}

/* line 101, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author .thumb img {
  width: auto;
}

/* line 106, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author h3 {
  color: #001D38;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

/* line 114, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author .icon a {
  color: #6c9720;
  font-size: 13px;
  margin-right: 3px;
}

/* line 120, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .single_testmonial .testmonial_author span {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}

/* line 127, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

/* line 131, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 0;
}

/* line 135, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.testimonial_area .owl-carousel .owl-nav div {
  left: 0;
}

/* line 141, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.Information_area {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 162px 0;
}

@media (max-width: 767px) {
  /* line 141, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
  .Information_area {
    padding: 100px 0;
  }
}
/* line 151, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.Information_area .info_text h3 {
  font-size: 46px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  /* line 151, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
  .Information_area .info_text h3 {
    font-size: 30px;
  }
}
/* line 160, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.Information_area .info_text p {
  font-size: 15px;
  font-weight: 400;
  color: #E8E8E8;
  margin: 25px 0;
}

/* line 166, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.Information_area .info_text a.boxed-btn3 {
  padding: 14px 40px;
}

/* line 174, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

/* line 177, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.newsletter_form input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding-left: 20px;
  font-size: 16px;
  color: #000;
  border: none;
}

/* line 185, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.newsletter_form input::placeholder {
  font-size: 16px;
  color: #919191;
}

/* line 190, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #A70000;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
}

/* line 204, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
.newsletter_text {
  font-size: 16px;
  color: #BABABA;
}

@media (max-width: 767px) {
  /* line 209, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_testmonial.scss */
  .custom_mt {
    margin-top: 100px;
  }
}
/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area {
  overflow: hidden;
  padding-top: 160px;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area {
    padding: 60px 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area {
    padding-top: 60px;
  }
}
/* line 11, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery {
  position: relative;
  height: 500px;
  float: left;
  overflow: hidden;
  border-right: 8px solid #fff;
  border-bottom: 8px solid #fff;
}

@media (max-width: 767px) {
  /* line 11, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery {
    height: auto;
    border: none;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  /* line 11, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery {
    height: 300px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  /* line 11, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 11, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery {
    height: 300px;
  }
}
/* line 33, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery a {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 12;
}

/* line 41, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery:before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background: #BB8151;
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 8;
}

/* line 54, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery img {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 60, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery.small_img {
  width: 30%;
}

@media (max-width: 767px) {
  /* line 60, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery.small_img {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 60, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery.small_img {
    width: 50%;
  }
}
/* line 69, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery.big_img {
  width: 40%;
}

@media (max-width: 767px) {
  /* line 69, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery.big_img {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 69, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery.big_img {
    width: 50%;
  }
}
@media (max-width: 767px) {
  /* line 69, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
  .gallery_area .single_gallery.big_img {
    width: 100%;
  }
}
/* line 88, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery:hover::before {
  opacity: 0.3;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* line 92, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_gallery.scss */
.gallery_area .single_gallery:hover img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area {
  padding-top: 140px;
  padding-bottom: 130px;
  position: relative;
}

/* line 5, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .rev_icon_3 {
  position: absolute;
  left: 2%;
  bottom: 4%;
}

/* line 10, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .rev_icon_1 {
  position: absolute;
  top: 2%;
  right: 4%;
}

/* line 15, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .rev_icon_2 {
  position: absolute;
  bottom: -12%;
  right: 4%;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
  .Reservation_area {
    padding: 50px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
  .Reservation_area {
    padding: 50px 0;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
  .Reservation_area {
    padding: 80px 0;
  }
}
/* line 29, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form {
  margin-bottom: 30px;
  background: #6c9720;
  padding: 34px 40px;
}

@media (max-width: 767px) {
  /* line 29, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
  .Reservation_area .book_Form {
    padding: 20px;
  }
}
/* line 36, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form h3 {
  font-size: 35px;
  font-family: "Philosopher", sans-serif;
  font-weight: 700;
  color: #fff;
  margin-bottom: 40px;
}

/* line 45, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .input_field input {
  width: 100%;
  height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: transparent;
  font-size: 14px;
  color: #fff;
  padding-left: 15px;
}

/* line 53, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .input_field input:focus {
  outline: none;
}

/* line 56, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .input_field input::placeholder {
  color: #fff;
  font-size: 14px;
}

/* line 62, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .sumbit_btn {
  width: 100%;
  background: #BB8151;
  display: block;
  padding: 11px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

/* line 72, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .sumbit_btn:hover {
  background: #B08151;
}

/* line 76, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .single_add {
  margin-top: 30px;
}

/* line 78, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .single_add .icon {
  margin-right: 14px;
}

/* line 82, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .single_add .ifno h4 {
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin-bottom: 0;
}

/* line 88, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .book_Form .single_add .ifno p {
  margin-bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

/* line 98, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .map_area {
  margin-bottom: 30px;
}

/* line 101, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .gj-datepicker.gj-datepicker-md.gj-unselectable span {
  color: #fff;
  top: 14px;
  font-size: 14px;
}

/* line 106, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .nice-select {
  width: auto;
  height: 45px;
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: transparent;
  color: #fff;
  line-height: 45px;
  width: 100%;
  margin-bottom: 15px;
}

/* line 117, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.Reservation_area .nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #000;
  font-size: 14px;
}

/* line 134, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.mb_15 {
  margin-bottom: 15px;
}

/* line 138, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_reservation.scss */
.gj-picker.gj-picker-md.datepicker.gj-unselectable .fa-chevron-left {
  position: absolute;
  left: 30px;
  top: 16px;
}

/* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area {
  padding-bottom: 110px;
  padding-top: 150px;
}

/* line 4, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu {
  border-bottom: 1px solid #E5E8EA;
  margin-bottom: 60px;
}

/* line 7, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav {
  margin-bottom: 0px;
}

/* line 9, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li {
  margin: 0 25px;
}

@media (max-width: 767px) {
  /* line 9, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
  .Delicious_area .tablist_menu .nav li {
    margin: 0 4px;
  }
}
/* line 14, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a {
  padding: 0 12px 27px 12px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* line 19, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a .single_menu .icon i {
  font-size: 50px;
  color: #596672;
}

/* line 24, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a .single_menu h4 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #596672;
  font-family: "Poppins", sans-serif;
}

/* line 32, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a.active {
  position: relative;
}

/* line 36, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a.active .single_menu .icon i {
  color: #6c9720;
}

/* line 40, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a.active .single_menu h4 {
  color: #6c9720;
}

/* line 44, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .tablist_menu .nav li a.active:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background: #6c9720;
  bottom: 0;
}

@media (max-width: 767px) {
  /* line 1, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
  .Delicious_area {
    padding-bottom: 50px;
  }
}
/* line 61, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .single_delicious {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  /* line 61, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
  .Delicious_area .single_delicious {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* line 61, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
  .Delicious_area .single_delicious {
    display: block !important;
  }
}
/* line 69, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .single_delicious .thumb {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* line 71, ../../Arafath/CL/Cl November/225. Restaurant-02/HTML/scss/_delicious.scss */
.Delicious_area .single_delicious .thumb img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.Delicious_area .single_delicious .info {
  padding-left: 28px;
}

@media (max-width: 767px) {
  .Delicious_area .single_delicious .info {
    padding-left: 0;
    margin-bottom: 15px;
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .Delicious_area .single_delicious .info {
    padding-left: 0;
    margin-top: 30px;
  }
}
.Delicious_area .single_delicious .info h3 {
  font-size: 24px;
  color: #001D38;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.Delicious_area .single_delicious .info p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 15px;
  margin-bottom: 16px;
}

.Delicious_area .single_delicious .info span {
  display: block;
  font-weight: 400;
  font-size: 26px;
  color: #6c9720;
}

.bradcam_bg_suculentas {
  background-image: url("./assets/img/bann-suculentas.jpg");
}

.bradcam_bg_livros {
  background-image: url("./assets/img/bann-livros.jpg");
}

.bradcam_bg_quadros {
  background-image: url("./assets/img/bann-quadros.jpg");
}

.bradcam_bg_paisagismo {
  background-image: url("./assets/img/paisagismo/paisagismo_1.jpeg");
}

.bradcam_bg_contato {
  background-image: url("./assets/img/bann-contato.jpg");
}

.bradcam_area {
  background-size: cover;
  background-position: center center;
  padding: 237px 0 178px 0;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .bradcam_area {
    padding: 120px 0;
  }
}
.bradcam_area::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001d38+0,1a0d01+100&0.7+0,0.6+100 */
  background: -moz-linear-gradient(left, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 29, 56, 0.7) 0%, rgba(26, 13, 1, 0.6) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#b3001d38", endColorstr="#991a0d01",GradientType=1 );
  /* IE6-9 */
  z-index: -1;
  content: "";
}

@media (max-width: 767px) {
  .bradcam_area {
    padding: 150px 0;
  }
}
.bradcam_area h1 {
  font-size: 70px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .bradcam_area h1 {
    font-size: 30px;
  }
}
.bradcam_area h3 {
  font-size: 70px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .bradcam_area h3 {
    font-size: 30px;
  }
}
.bradcam_area p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
}

.bradcam_area p a {
  color: #fff;
}

.bradcam_area p a:hover {
  color: #fff;
}

.bradcam_area .bradcam_text2 h3 {
  font-size: 50px;
  margin-bottom: 14px;
}

@media (max-width: 767px) {
  .bradcam_area .bradcam_text2 h3 {
    font-size: 30px;
  }
}
.bradcam_area .bradcam_text2 p {
  font-size: 20px;
  margin-bottom: 0;
}

.bradcam_area.Extra_padding {
  padding: 399px 0;
}

@media (max-width: 767px) {
  .bradcam_area.Extra_padding {
    padding: 150px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .bradcam_area.Extra_padding {
    padding: 150px 0;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .bradcam_area.Extra_padding {
    padding: 150px 0;
  }
}
.popup_box {
  background: #fff;
  display: inline-block;
  z-index: 9;
  width: 558px;
}

.popup_box .boxed-btn3 {
  width: 100%;
  text-transform: capitalize;
}

.popup_box .popup_header {
  background: #F5FBFF;
  padding: 28px 0;
  display: block;
}

.popup_box .popup_header h3 {
  text-align: center;
  font-size: 20px;
  color: #2C2C2C;
  margin-bottom: 0;
  font-weight: 400;
}

.popup_box .custom_form {
  padding: 60px 68px;
}

.popup_box input {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid #C7C7C7;
  padding: 15px 0;
  margin-bottom: 20px;
}

.popup_box input::placeholder {
  color: #919191;
  font-weight: 400;
}

.popup_box input:focus {
  outline: none;
}

.popup_box textarea {
  width: 100%;
  border: none;
  margin-top: 112px;
  border-bottom: 1px solid #C7C7C7;
  padding: 15px 0;
  margin-bottom: 20px;
  height: 55px;
  resize: none;
  margin-bottom: 40px;
}

.popup_box textarea::placeholder {
  color: #919191;
  font-weight: 400;
}

.popup_box textarea:focus {
  outline: none;
}

.popup_box .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  /* border-radius: 5px; */
  border: solid 1px #E2E2E2;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  width: 100% !important;
  /* height: 42px; */
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
  border-radius: 0;
  margin-bottom: 30px;
  height: 50px !important;
  font-size: 16px;
  font-weight: 400;
  color: #919191;
}

.popup_box .nice-select::after {
  content: "";
  display: block;
  height: 5px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 17px;
  top: 3px;
  transition: all 0.15s ease-in-out;
  width: 5px;
  font-family: fontawesome;
  color: #919191;
  font-size: 15px;
}

.popup_box .nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  height: 200px;
  overflow-y: scroll;
}

.popup_box .nice-select.list {
  height: 200px;
  overflow-y: scroll;
}

#test-form {
  display: inline-block;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#test-form .mfp-close-btn-in .mfp-close {
  color: #333;
  display: none !important;
}

#test-form button.mfp-close {
  display: none !important;
}

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000000;
  opacity: 0.7;
}

.service_area {
  padding-top: 100px;
  padding-bottom: 70px;
}

.service_area.minus_padding {
  padding-bottom: 0;
}

.service_area .single_service {
  padding: 30px;
  border: 1px solid #E8E8E8;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 30px;
}

.service_area .single_service .service_icon {
  line-height: 94px;
  text-align: center;
  width: 98px;
  height: 98px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0181f5+0,5db2ff+100 */
  background: #0181f5;
  /* Old browsers */
  background: -moz-linear-gradient(top, #0181f5 0%, #5db2ff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #0181f5 0%, #5db2ff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #0181f5 0%, #5db2ff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#0181f5", endColorstr="#5db2ff",GradientType=0 );
  /* IE6-9 */
}

.service_area .single_service h3 {
  font-size: 22px;
  color: #001D38;
  font-weight: 300;
  margin-top: 35px;
  margin-bottom: 23px;
}

.service_area .single_service p {
  font-size: 15px;
  font-weight: 400;
  color: #727272;
  line-height: 28px;
  margin-bottom: 6px;
}

.service_area .single_service a.learn_more {
  color: #5DB2FF;
  font-weight: 400;
  font-size: 15px;
}

.service_area .single_service:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04);
  border-color: transparent;
}

.service_area .col-xl-4:nth-child(2) .single_service .service_icon {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00d363+0,37ef8d+100 */
  background: #00d363;
  /* Old browsers */
  background: -moz-linear-gradient(top, #00d363 0%, #37ef8d 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00d363 0%, #37ef8d 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00d363 0%, #37ef8d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00d363", endColorstr="#37ef8d",GradientType=0 );
  /* IE6-9 */
}

.service_area .col-xl-4:nth-child(3) .single_service .service_icon {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fd8e5e+0,fd8e5e+100 */
  background: #fd8e5e;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fd8e5e 0%, #fd8e5e 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #fd8e5e 0%, #fd8e5e 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #fd8e5e 0%, #fd8e5e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#fd8e5e", endColorstr="#fd8e5e",GradientType=0 );
  /* IE6-9 */
}

.service_area .col-xl-4:nth-child(4) .single_service .service_icon {
  background: #615cfd;
  /* Old browsers */
  background: -moz-linear-gradient(top, #615cfd 0%, #d465ef 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #615cfd 0%, #d465ef 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #615cfd 0%, #d465ef 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#615cfd", endColorstr="#d465ef",GradientType=0 );
  /* IE6-9 */
}

.service_area .col-xl-4:nth-child(6) .single_service .service_icon {
  background: #a64eee;
  /* Old browsers */
  background: -moz-linear-gradient(top, #a64eee 0%, #3c35ce 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #a64eee 0%, #3c35ce 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #a64eee 0%, #3c35ce 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a64eee", endColorstr="#3c35ce",GradientType=0 );
  /* IE6-9 */
}

.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #1A0D01;
  position: relative;
  z-index: 9;
}

.footer .footer_top {
  padding-top: 145px;
  padding-bottom: 129px;
}

@media (max-width: 767px) {
  .footer .footer_top {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}
.footer .footer_top .footer_widget .footer_title {
  font-size: 18px;
  font-weight: 500;
  color: #6c9720;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}
.footer .footer_top .footer_widget .footer_logo {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .footer .footer_top .footer_widget .footer_logo {
    margin-bottom: 20px;
  }
}
.footer .footer_top .footer_widget p {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.footer .footer_top .footer_widget p a {
  color: #919191;
}

.footer .footer_top .footer_widget p a:hover {
  color: #6c9720;
}

.footer .footer_top .footer_widget p.footer_text {
  font-size: 16px;
  color: #B2B2B2;
  margin-bottom: 23px;
  font-weight: 400;
  line-height: 28px;
}

.footer .footer_top .footer_widget p.footer_text a.domain {
  color: #B2B2B2;
  font-weight: 400;
}

.footer .footer_top .footer_widget p.footer_text a.domain:hover {
  color: #6c9720;
  border-bottom: 1px solid #6c9720;
}

.footer .footer_top .footer_widget p.footer_text.doanar a {
  font-weight: 500;
  color: #B2B2B2;
}

.footer .footer_top .footer_widget p.footer_text.doanar a:hover {
  color: #6c9720;
  border-bottom: 1px solid #6c9720;
}

.footer .footer_top .footer_widget p.footer_text.doanar a.first {
  margin-bottom: 10px;
}

.footer .footer_top .footer_widget ul li {
  color: #ACACAC;
  font-size: 14px;
  line-height: 36px;
}

.footer .footer_top .footer_widget ul li a {
  color: #919191;
  font-weight: 400;
}

.footer .footer_top .footer_widget ul li a:hover {
  color: #6c9720;
}

.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}

.footer .footer_top .footer_widget .newsletter_form input {
  width: 100%;
  height: 50px;
  background: #F5FBFF;
  padding-left: 20px;
  font-size: 15px;
  color: #000;
  border: none;
  border: 1px solid #E8E8E8;
  border-radius: 30px;
}

.footer .footer_top .footer_widget .newsletter_form input::placeholder {
  font-size: 15px;
  color: #919191;
}

.footer .footer_top .footer_widget .newsletter_form input:focus {
  outline: none;
}

.footer .footer_top .footer_widget .newsletter_form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  border: none;
  font-size: 14px;
  color: #fff;
  background: #6c9720;
  padding: 10px;
  padding: 0 22px;
  cursor: pointer;
  border-radius: 30px;
  top: 5px;
  right: 5px;
  font-size: 14px;
  font-weight: 500;
}

.footer .footer_top .footer_widget .newsletter_text {
  font-size: 14px;
  color: #919191;
  line-height: 26px;
}

.footer .copy-right_text {
  padding-bottom: 30px;
}

.footer .copy-right_text .footer_border {
  border-top: 1px solid #31261B;
  padding-bottom: 30px;
}

.footer .copy-right_text .copy_right {
  font-size: 15px;
  color: #919191;
  margin-bottom: 0;
  font-weight: 500;
}

@media (max-width: 767px) {
  .footer .copy-right_text .copy_right {
    font-size: 14px;
  }
}
.footer .copy-right_text .copy_right a {
  color: #6c9720;
}

.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {
  .footer .socail_links {
    margin-top: 30px;
  }
}
.footer .socail_links ul li {
  display: inline-block;
}

.footer .socail_links ul li a {
  font-size: 15px;
  color: #C3B2F0;
  width: 30px;
  display: inline-block;
  text-align: center;
  background: transparent;
  margin-right: 7px;
  color: #FFFFFF;
  line-height: 40px !important;
  color: #E8E8E8;
}

.footer .socail_links ul li a:hover {
  color: #6c9720 !important;
  border-color: transparent;
}

/*=================== contact banner start ====================*/
.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-info {
  margin-bottom: 25px;
}

.contact-info__icon {
  margin-right: 20px;
}

.contact-info__icon i, .contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-size: 16px;
  color: #2a2a2a;
}

.contact-info .media-body h3 a:hover {
  color: #ff5e13;
}

.contact-info .media-body p {
  color: #8a8a8a;
}

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-contact label {
  font-size: 14px;
}

.form-contact .form-group {
  margin-bottom: 30px;
}

.form-contact .form-control {
  border: 1px solid #e5e6e9;
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

.form-contact textarea {
  border-radius: 0px;
  height: 100% !important;
}

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

.contact-section {
  padding: 130px 0 100px;
}

@media (max-width: 991px) {
  .contact-section {
    padding: 70px 0 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .contact-section {
    padding: 80px 0 50px;
  }
}
.contact-section .btn_2 {
  background-color: #191d34;
  padding: 18px 60px;
  border-radius: 50px;
  margin-top: 0;
}

.contact-section .btn_2:hover {
  background-color: #ff5e13;
}

.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

b,
sup,
sub,
u,
del {
  color: #ff5e13;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2;
}

.button-area {
  background: #fff;
}

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.genric-btn:focus {
  outline: none;
}

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

.genric-btn.large {
  line-height: 45px;
}

.genric-btn.medium {
  line-height: 30px;
}

.genric-btn.small {
  line-height: 25px;
}

.genric-btn.radius {
  border-radius: 3px;
}

.genric-btn.circle {
  border-radius: 20px;
}

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.genric-btn.arrow span {
  margin-left: 10px;
}

.genric-btn.default {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.primary {
  color: #fff;
  background: #ff5e13;
  border: 1px solid transparent;
}

.genric-btn.primary:hover {
  color: #ff5e13;
  border: 1px solid #ff5e13;
  background: #fff;
}

.genric-btn.primary-border {
  color: #ff5e13;
  border: 1px solid #ff5e13;
  background: #fff;
}

.genric-btn.primary-border:hover {
  color: #fff;
  background: #ff5e13;
  border: 1px solid transparent;
}

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.link {
  color: #415094;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

.genric-btn.link:hover {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.link-border {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

.genric-btn.link-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #ff5e13;
}

.progress-table-wrap {
  overflow-x: scroll;
}

.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

.progress-table .country {
  width: 28.07%;
}

.progress-table .visit {
  width: 19.74%;
}

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

.progress-table .table-head {
  display: flex;
}

.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #415094;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

.progress-table .table-row .country img {
  margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

.list-style {
  width: 14px;
  height: 14px;
}

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #ff5e13;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.ordered-list {
  margin-left: 30px;
}

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list li span {
  font-weight: 300;
  color: #828bb2;
}

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-alpha li span {
  font-weight: 300;
  color: #828bb2;
}

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #ff5e13;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-roman li span {
  font-weight: 300;
  color: #828bb2;
}

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input:focus {
  outline: none;
}

.input-group-icon {
  position: relative;
}

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

.input-group-icon .icon i {
  color: #797979;
}

.input-group-icon .single-input {
  padding-left: 45px;
}

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

.single-textarea:focus {
  outline: none;
}

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-primary:focus {
  outline: none;
  border: 1px solid #ff5e13;
}

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff5e13;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.default-switch input:checked + label {
  left: 19px;
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.primary-switch input:checked + label:after {
  left: 19px;
}

.primary-switch input:checked + label:before {
  background: #ff5e13;
}

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirm-switch input:checked + label:after {
  left: 19px;
}

.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-checkbox input:checked + label {
  border: none;
}

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked + label {
  border: none;
}

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-checkbox input:checked + label {
  border: none;
}

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-radio input:checked + label {
  border: none;
}

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-radio input:checked + label {
  border: none;
}

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-radio input:checked + label {
  border: none;
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #ff5e13;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #ff5e13;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

.form-select {
  height: 40px;
  width: 100%;
}

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
  color: #ff5e13;
  background: transparent;
}

.form-select .nice-select .list .option:hover {
  color: #ff5e13;
  background: transparent;
}

.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.form-select .nice-select::after {
  right: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.switch-wrap {
  margin-bottom: 10px;
}

/* Causes Area css
============================================================================================ */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

.causes_item {
  background: #fff;
}

.causes_item .causes_img {
  position: relative;
}

.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  color: #fff;
  padding: 0px 5px;
}

.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

.causes_item .causes_text h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 0px;
}

.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid;
  text-align: center;
  float: left;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
}

/*# sourceMappingURL=style.css.map */
a {
  cursor: pointer;
}